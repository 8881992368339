import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBContainer,
    MDBInput,
    MDBRow,
    MDBCol,
} from "mdb-react-ui-kit";
import Spinner from "../components/Spinner";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changePassword, reset } from "../features/auth/authSlice";
import {
    setErrorMessage,
    setErrorMessageList,
} from "../features/messages/alertMessageSlice";
import { toast } from "react-toastify";
import AlertMessage from "../components/AlertMessage";

const ChangePassword = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        password: "",
        password_confirmation: "",
    });

    const { password, password_confirmation } = formData;
    const { token, email } = params;

    const { isLoading, isError, isSuccess, message, errorList } = useSelector(
        (state) => state.auth
    );
    const errorListRef = useRef([])

    useEffect(() => {
        errorListRef.current = errorList.password
    })

    useEffect(() => {
        if (token === undefined) {
            dispatch(setErrorMessage("No token provided!"));
        }
        if (email === undefined) {
            dispatch(setErrorMessage("No email provided!"));
        }

        if (isError) {
            toast.error(message);
            if(errorListRef.current){
                dispatch(setErrorMessageList(errorListRef.current));
            } else {
                dispatch(setErrorMessage(message));
            }
        }

        if (isSuccess) {
            navigate("/");
            toast.success(message);
        }

        return () => {
            dispatch(reset());
        };
    }, [
        token,
        email,
        isError,
        isSuccess,
        message,
        dispatch,
        navigate,
    ]);

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (password !== password_confirmation) {
            toast.error("Passwords do not match");
        } else {
            const userData = {
                token,
                email,
                password,
                password_confirmation,
            };

            dispatch(changePassword(userData));
        }
    };

    return (
        <MDBContainer className="pt-5">
            {isLoading ? <Spinner /> : <></>}
            <MDBRow className="row d-flex justify-content-center">
                <MDBCol md="6">
                    <MDBCard className="text-center">
                        <MDBCardHeader className="p-4">
                            <h5 className="mb-0">
                                <strong>Change password</strong>
                            </h5>
                        </MDBCardHeader>
                        <MDBCardBody className="p-4">
                            <AlertMessage></AlertMessage>
                            <p className="mb-4">Set a new password</p>

                            <form onSubmit={onSubmit} className="mb-4">
                                <input
                                    type="hidden"
                                    name="token"
                                    value={token}
                                />
                                <input
                                    type="hidden"
                                    name="email"
                                    value={email}
                                />
                                <MDBInput
                                    label="New password"
                                    className="mb-4"
                                    type="password"
                                    id="resetPassword"
                                    name="password"
                                    value={password}
                                    onChange={onChange}
                                    placeholder="Enter your password"
                                    required
                                />
                                <MDBInput
                                    label="Confirm password"
                                    className="mb-4"
                                    type="password"
                                    id="confirmPassword"
                                    name="password_confirmation"
                                    value={password_confirmation}
                                    onChange={onChange}
                                    placeholder="Enter your password again"
                                    required
                                />

                                <MDBBtn type="submit">Apply</MDBBtn>
                            </form>

                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <Link to="/login">Back to Log In</Link>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default ChangePassword;
