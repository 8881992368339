const authorizationCheck = (user, userRules, allowedRules, allowedIds) => {
    const rulesCheck = allowedRules.some((rule) => userRules.includes(rule))
    const idCheck = allowedIds.includes(user?.id)

    if (rulesCheck) {
        return true
    }

    if (idCheck) {
        return true
    }

    return false
}

export default authorizationCheck
