import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBContainer,
    MDBInput,
    MDBRow,
    MDBCol,
} from "mdb-react-ui-kit"
import Spinner from "../components/Spinner"
import { Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { forgotPassword, reset } from "../features/auth/authSlice"
import {
    setErrorMessage,
    setSuccessMessage,
} from "../features/messages/alertMessageSlice"
import { toast } from "react-toastify"
import AlertMessage from "../components/AlertMessage"

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        email: "",
    })
    const [disabled, setDisabled] = useState(false)
    const { user, isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )

    const { email } = formData

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    useEffect(() => {
        if (isError) {
            toast.error(message)
            dispatch(setErrorMessage(message))
        }
        if (isSuccess) {
            setDisabled(true)
            dispatch(setSuccessMessage(message))
        }

        return () => {
            dispatch(reset())
        }
    }, [isError, isSuccess, message, dispatch])

    const onSubmit = (e) => {
        e.preventDefault()

        const userData = {
            email,
        }

        dispatch(forgotPassword(userData))
    }

    return (
        <MDBContainer className="mt-5 pt-5">
            {isLoading ? <Spinner /> : <></>}
            <MDBRow className="d-flex justify-content-center">
                <MDBCol md="8">
                    <MDBCard className="text-center">
                        <MDBCardHeader className="p-4">
                            <h5 className="mb-0">
                                <strong>Forgot Password</strong>
                            </h5>
                        </MDBCardHeader>
                        <MDBCardBody className="p-4">
                            <p className="mb-4">
                                Enter your email address and we’ll send a link
                                to reset your password
                            </p>
                            <AlertMessage></AlertMessage>
                            <form onSubmit={onSubmit} className="mb-4">
                                <MDBInput
                                    type="email"
                                    id="emailInput"
                                    className="mb-4"
                                    name="email"
                                    value={email}
                                    onChange={onChange}
                                    placeholder="Enter your email"
                                    required
                                    label="Your e-mail address"
                                    disabled={disabled}
                                />

                                <MDBBtn type="submit" disabled={disabled}>
                                    Request password
                                </MDBBtn>
                            </form>

                            {user ? (
                                <></>
                            ) : (
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <Link to="/login">Back to Log In</Link>
                                </div>
                            )}
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default ForgotPassword
