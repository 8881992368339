import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alertMessage: "",
    messageList: [],
    isError: false,
    isSuccess: false,
};

export const alertMessageSlice = createSlice({
    name: "alertMessage",
    initialState,
    reducers: {
        resetMessage: (state) => {
            state.alertMessage = "";
            state.messageList = [];
            state.isError = false;
            state.isSuccess = false;
        },
        setErrorMessage: (state, action) => {
            state.alertMessage = action.payload;
            state.messageList = [];
            state.isError = true;
            state.isSuccess = false;
        },
        setSuccessMessage: (state, action) => {
            state.alertMessage = action.payload;
            state.messageList = [];
            state.isError = false;
            state.isSuccess = true;
        },
        setErrorMessageList: (state, action) => {
            state.alertMessage = "";
            state.messageList = action.payload;
            state.isError = true;
            state.isSuccess = false;
        },
    },
});

export const {
    resetMessage,
    setErrorMessage,
    setSuccessMessage,
    setErrorMessageList,
} = alertMessageSlice.actions;
export default alertMessageSlice.reducer;
