import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import apiErrorMessageParser from "../../utils/apiErrorParser";

// Get user from localstorage
const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    errorList: [],
};

// Register new user
export const register = createAsyncThunk(
    "auth/register",
    async (user, thunkAPI) => {
        try {
            return await authService.register(user);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Login user
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
    try {
        return await authService.login(user);
    } catch (error) {
        const message = apiErrorMessageParser(error);

        return thunkAPI.rejectWithValue(message);
    }
});

// Logout user
export const logout = createAsyncThunk("auth/logout", async () => {
    await authService.logout();
});

// Forgot Password
export const forgotPassword = createAsyncThunk(
    "auth/forgot-password",
    async (userData, thunkAPI) => {
        try {
            return await authService.forgotPassword(userData);
        } catch (error) {
            const message = apiErrorMessageParser(error);

            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Change Password
export const changePassword = createAsyncThunk(
    "auth/change-password",
    async (userData, thunkAPI) => {
        try {
            return await authService.changePassword(userData);
        } catch (error) {
            if(error.response.data.errors){
                return thunkAPI.rejectWithValue(error.response.data);
            }
            const message = apiErrorMessageParser(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = "";
            state.errorList = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            })
            .addCase(forgotPassword.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.message;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(changePassword.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.message;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload.message;
                state.errorList = action.payload.errors;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.user = null;
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = false;
                state.message = "";
            });
    },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
