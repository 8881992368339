import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import Cookies from "universal-cookie";
import { token } from "../utils/authorizationUtils"

// const cookies = new Cookies();

export const useAuthStatus = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userRules, setUserRules] = useState([]);
    const [checkingStatus, setCheckingStatus] = useState(true);

    const { user } = useSelector((state) => state.auth);
    const userHasCookie = token !== undefined;
    // const userHasCookie = cookies.get("X-MHMAPP-X-ACCESS-TOKEN") !== undefined;

    useEffect(() => {
        if (user && userHasCookie) {
            setLoggedIn(true);
            setUserRules(
                Object.keys(user.rules).filter((rule) => {
                    return !rule.endsWith("_limits");
                })
            );
        } else {
            setLoggedIn(false);
        }
        setCheckingStatus(false);
    }, [user, userHasCookie]);

    return { loggedIn, checkingStatus, user, userRules };
};
