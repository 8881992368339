import { MDBRow, MDBCol, MDBContainer } from "mdb-react-ui-kit"
import MainWrapper from "../components/MainWrapper"

function VersionCheck() {
    const centeredOnPage = {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    }

    return (
        <MainWrapper>
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={centeredOnPage}
                        >
                            This app build is running on version{" "}
                            {process.env.REACT_APP_VERSION}
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MainWrapper>
    )
}

export default VersionCheck
