import { MDBFooter } from "mdb-react-ui-kit"

const MainWrapper = (props) => {
    const { showFooter } = props

    return (
        <>
            <main>{props.children}</main>
            {showFooter ? (
                <MDBFooter bgColor="light" className="text-left fixed-bottom">
                    <div
                        className="text-center"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                    >
                        v{process.env.REACT_APP_VERSION}
                    </div>
                </MDBFooter>
            ) : (
                <></>
            )}
        </>
    )
}

// Set default props
MainWrapper.defaultProps = {
    showFooter: false,
}

export default MainWrapper
