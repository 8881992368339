import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBInput,
    MDBRadio,
    MDBRow,
    MDBSelect,
    MDBValidation,
    MDBValidationItem,
} from "mdb-react-ui-kit"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import MainWrapper from "../../../components/MainWrapper"
import Spinner from "../../../components/Spinner"
import {
    getEcomClients,
    getStorageClients,
    getAvailableReceptions,
    createDocument,
} from "../../../features/storage/inventory_documents/inventoryDocumentsSlice"

const initialFormData = {
    department: "",
    client: "",
    doc_type: "",
    doc_no: "",
    doc_date: "",
    reception_date: "",
    docs: [],
}

const initialFormContents = [{ description: "", quantity: "" }]

function NewInventoryDocument() {
    const {
        ecomClients,
        storageClients,
        availableReceptions,
        isLoading,
        isError,
        isSuccess,
        message,
        errorList,
        docsErrorMessage,
    } = useSelector((state) => state.inventoryDocuments)
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(initialFormData)

    const {
        department,
        client,
        doc_type,
        rec_id,
        doc_no,
        doc_date,
        reception_date,
        docs,
    } = formData

    const mappedClients = (department) => {
        let arr = []
        if (department === "ecommerce") {
            arr = ecomClients.map((ecomClient) => {
                return {
                    text: ecomClient.auth_group.name,
                    value: ecomClient.group_id,
                    selected: client === ecomClient.group_id ? true : false,
                }
            })
        }
        if (department === "storage") {
            arr = storageClients.map((storageClient) => {
                return {
                    text: storageClient.auth_group.name,
                    value: storageClient.group_id,
                    selected: client === storageClient.group_id ? true : false,
                }
            })
        }

        return [{ text: "", value: "", selected: false }, ...arr]
    }

    const mappedAvailableReceptions = () => {
        const arr = availableReceptions.map((reception) => {
            return {
                text: reception.id + " / " + reception.reception_name,
                value: reception.id,
                selected: rec_id === reception.id ? true : false,
            }
        })
        return [{ text: "", value: "", selected: false }, ...arr]
    }

    const onFormChange = (e) => {
        if (e.target.name === "docs") {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.files,
            }))
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        }
    }
    const onSelectChange = (e, inputName) => {
        if (
            department === "ecommerce" &&
            inputName === "client" &&
            e !== undefined &&
            client !== e.value &&
            e.value !== ""
        ) {
            dispatch(getAvailableReceptions(e.value))
            setFormData((prevState) => ({
                ...prevState,
                doc_no: "",
            }))
        }

        if (inputName === "rec_id" && e !== undefined) {
            const selectedRec = availableReceptions.filter((reception) => {
                return reception.id === parseInt(e.text.split(" / ")[0])
            })

            setFormData((prevState) => ({
                ...prevState,
                doc_no: selectedRec[0].reception_no,
            }))
        }

        if (e !== undefined) {
            setFormData((prevState) => ({
                ...prevState,
                [inputName]: e.value,
            }))
        }
    }

    const onDepartmentChange = (e) => {
        if (e.target.value === "ecommerce") {
            dispatch(getEcomClients())
        }
        if (e.target.value === "storage") {
            dispatch(getStorageClients())
        }
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
            client: undefined,
        }))
    }

    /**
     * Contents
     */
    const [formContents, setFormContents] = useState([
        { description: "", quantity: "" },
    ])

    const handleChange = (i, e) => {
        const newFormConents = [...formContents]
        newFormConents[i][e.target.name] = e.target.value
        setFormContents(newFormConents)
    }

    const addFormFields = () => {
        setFormContents([...formContents, { description: "", quantity: "" }])
    }

    const removeFormFields = (i) => {
        let newFormConents = [...formContents]
        newFormConents.splice(i, 1)
        setFormContents(newFormConents)
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const payload = new FormData()
        payload.append("department", department)
        payload.append("client", client)
        payload.append("doc_type", doc_type)
        payload.append("rec_id", rec_id)
        payload.append("doc_no", doc_no)
        payload.append("doc_date", doc_date)
        payload.append("reception_date", reception_date)

        for (const key of Object.keys(docs)) {
            payload.append("docs[]", docs[key])
        }

        formContents.forEach((content, key) => {
            const { description, quantity } = content
            payload.append(`contents[${key}][description]`, description)
            payload.append(`contents[${key}][quantity]`, quantity)
        })

        // console.log(payload.getAll("contents[]"))

        dispatch(createDocument(payload))
    }

    useEffect(() => {
        if (isSuccess) {
            toast.success("Inventory Document Created!")
            setFormData(initialFormData)
            setFormContents(initialFormContents)
        }
        // return () => {
        //     dispatch(stateReset())
        // }
    }, [isError, dispatch, isSuccess, message])

    if (isLoading) {
        return <Spinner />
    }

    return true ? (
        <MainWrapper>
            <MDBRow>
                <MDBCol>
                    <MDBCard className="m-5" border="primary">
                        <MDBCardHeader tag="h4">
                            New Inventory Document
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBValidation
                                encType="multipart/form-data"
                                onSubmit={onSubmit}
                            >
                                <div
                                    className="d-flex justify-content-center"
                                    onChange={onDepartmentChange}
                                >
                                    <label className="me-2">
                                        Select Department:
                                    </label>
                                    <MDBRadio
                                        name="department"
                                        id="ecommerce"
                                        value="ecommerce"
                                        label="Ecommerce"
                                        inline
                                        checked={department === "ecommerce"}
                                        onChange={(e) => {}}
                                        required
                                    />
                                    <MDBRadio
                                        name="department"
                                        id="storage"
                                        value="storage"
                                        label="Storage"
                                        inline
                                        checked={department === "storage"}
                                        onChange={(e) => {}}
                                        required
                                    />
                                </div>
                                {department !== "" ? (
                                    <MDBSelect
                                        className="mb-4"
                                        id="client_list"
                                        label="Clients"
                                        name="client"
                                        data={mappedClients(department)}
                                        getValue={(e) =>
                                            onSelectChange(e, "client")
                                        }
                                        validation
                                        validFeedback="This value is valid"
                                        invalidFeedback="This value is invalid"
                                        autoComplete="off"
                                        search
                                        required
                                    />
                                ) : (
                                    <></>
                                )}

                                {client !== "" ? (
                                    <>
                                        <MDBSelect
                                            className="mb-2"
                                            id="doc_type"
                                            label="Document Type"
                                            name="doc_type"
                                            data={[
                                                { text: "PV", value: "pv", selected: doc_type === "pv" ? true : false },
                                                { text: "Aviz", value: "aviz", selected: doc_type === "aviz" ? true : false },
                                                { text: "CMR", value: "cmr", selected: doc_type === "cmr" ? true : false },
                                                { text: "AWB", value: "awb", selected: doc_type === "awb" ? true : false },
                                            ]}
                                            getValue={(e) =>
                                                onSelectChange(e, "doc_type")
                                            }
                                            required
                                        />
                                        {errorList.doc_type ? 
                                        <p className="form-text small text-danger mb-2">{errorList.doc_type}</p>
                                        : <></>}

                                        {department === "ecommerce" ? (<><MDBSelect
                                            className="mb-2"
                                            id="rec_id"
                                            label="Reception"
                                            name="rec_id"
                                            data={mappedAvailableReceptions()}
                                            getValue={(e) =>
                                                onSelectChange(e, "rec_id")
                                            }
                                            required
                                        />
                                        {errorList.rec_id ? 
                                        <p className="form-text small text-danger mb-2">{errorList.rec_id}</p>
                                        : <></>}
                                        </>
                                        
                                        )
                                        
                                        : <></> }
                                        <MDBValidationItem feedback={errorList.doc_no ? errorList.doc_no[0] : ""} invalid={errorList.doc_no ? true : false}>
                                        <MDBInput
                                            className={errorList.doc_no ? "mb-5 is-invalid" : "mb-4"}
                                            type="text"
                                            id="doc_no"
                                            label={doc_no === "" ? "Document Number" : ""}
                                            name="doc_no"
                                            value={doc_no}
                                            onChange={onFormChange}
                                            required
                                            disabled={department === "ecommerce" ? true : false}
                                        />
                                        </MDBValidationItem>
                                        <MDBValidationItem feedback={errorList.doc_date ? errorList.doc_date[0] : ""} invalid={errorList.doc_date ? true : false}>
                                        <MDBInput
                                            className={errorList.doc_date ? "mb-5 is-invalid" : "mb-4"}
                                            type="date"
                                            id="doc_date"
                                            label="Document Date"
                                            name="doc_date"
                                            value={doc_date}
                                            onChange={onFormChange}
                                            required
                                        />
                                        </MDBValidationItem>
                                        <MDBValidationItem feedback={errorList.reception_date ? errorList.reception_date[0] : ""} invalid={errorList.reception_date ? true : false}>
                                        <MDBInput
                                            className={errorList.reception_date ? "mb-5 is-invalid" : "mb-4"}
                                            type="date"
                                            id="reception_date"
                                            label="Reception Date"
                                            name="reception_date"
                                            value={reception_date}
                                            onChange={onFormChange}
                                            required
                                        />
                                        </MDBValidationItem>
                                        <MDBValidationItem feedback={docsErrorMessage} invalid={docsErrorMessage !== "" ? true : false}>
                                        <MDBInput
                                            className={docsErrorMessage !== "" ? "mb-5 is-invalid" : "mb-4"}
                                            type="file"
                                            id="docs"
                                            name="docs"
                                            onChange={onFormChange}
                                            multiple
                                        />
                                        </MDBValidationItem>

                                        {formContents.map((element, index) => (
                                            <MDBRow key={index}>
                                                <MDBCol size="4">
                                                    <MDBValidationItem
                                                        feedback={
                                                            errorList[`contents.${index}.description`]
                                                                ? errorList[`contents.${index}.description`]
                                                                : ""
                                                        }
                                                        invalid={errorList[`contents.${index}.description`] ? true : false}
                                                    >
                                                        <MDBInput
                                                            className={
                                                                errorList[`contents.${index}.description`]
                                                                    ? "mb-5 is-invalid"
                                                                    : "mb-4"
                                                            }
                                                            type="text"
                                                            label="Content"
                                                            name="description"
                                                            value={element.description || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            required
                                                        />
                                                    </MDBValidationItem>
                                                </MDBCol>
                                                <MDBCol size="4">
                                                    <MDBValidationItem
                                                        feedback={
                                                            errorList[`contents.${index}.quantity`]
                                                                ? errorList[`contents.${index}.quantity`]
                                                                : ""
                                                        }
                                                        invalid={errorList[`contents.${index}.quantity`] ? true : false}
                                                    >
                                                        <MDBInput
                                                            className={
                                                                errorList[`contents.${index}.quantity`]
                                                                    ? "mb-5 is-invalid"
                                                                    : "mb-4"
                                                            }
                                                            wrapperStyle={{ color: "red" }}
                                                            type="number"
                                                            label="Quantity"
                                                            name="quantity"
                                                            value={element.quantity || ""}
                                                            onChange={(e) => handleChange(index, e)}
                                                            required
                                                            min="1"
                                                        />
                                                    </MDBValidationItem>
                                                </MDBCol>
                                                <MDBCol size="4">
                                                    {index ? (
                                                        <MDBBtn
                                                            type="button"
                                                            className="me-2"
                                                            onClick={() => removeFormFields(index)}
                                                        >
                                                            Remove
                                                        </MDBBtn>
                                                    ) : null}
                                                    <MDBBtn type="button" onClick={() => addFormFields()}>
                                                        Add
                                                    </MDBBtn>
                                                </MDBCol>
                                            </MDBRow>
                                        ))}

                                        <MDBBtn type="submit" block>
                                            Save
                                        </MDBBtn>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </MDBValidation>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MainWrapper>
    ) : (
        <></>
    )
}

export default NewInventoryDocument
