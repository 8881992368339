import { useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    getStorageUsers,
    getProjects,
    getOperatorTimeActivities,
    deleteActivity,
    stateReset,
    reset,
} from "../../../features/storage/operators_time_activity/operatorsTimeActivitySlice"
import { setErrorMessage } from "../../../features/messages/alertMessageSlice"
import Spinner from "../../../components/Spinner"
import MainWrapper from "../../../components/MainWrapper"
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow, MDBInput, MDBSelect, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import ReactPaginate from "react-paginate"
import AlertMessage from "../../../components/AlertMessage"

function OperatorTimeActivities() {
    const { storageUsers, projects, activities, activitiesPagination, isLoading, isError, isDeleted, message } = useSelector(
        (state) => state.operatorsTimeActivity
    )
    const [currentPage, setCurrentPage] = useState(1)
    const [date, setDate] = useState(new Date().toJSON().slice(0, 10))
    const [operatorId, setOperatorId] = useState()
    const [projectId, setProjectId] = useState()

    const dispatch = useDispatch()

    const [selectUserOptions, setSelectUserOptions] = useState([])
    const mappedUsers = useCallback(() => {
        const users = storageUsers.map((storageUser) => {
            return {
                text: storageUser.name,
                value: storageUser.id,
                selected: operatorId === storageUser.id ? true : false,
            }
        })

        return [{ text: "", value: undefined, defaultSelected: false }, ...users]
    }, [storageUsers, operatorId])

    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const mappedProjects = useCallback(() => {
        const arr = projects.map((project) => {
            return {
                text: project.projectIdentifier,
                value: project.id,
                selected: projectId === project.id ? true : false,
            }
        })

        return [{ text: "", value: undefined, defaultSelected: false }, ...arr]
    }, [projects, projectId])

    useEffect(() => {
        setSelectProjectOptions(mappedProjects())
    }, [mappedProjects, projectId])

    useEffect(() => {
        setSelectUserOptions(mappedUsers())
    }, [mappedUsers, operatorId])

    useEffect(() => {
        if (isError) {
            dispatch(setErrorMessage(message))
        }
        if (isDeleted) {
            dispatch(setErrorMessage("Activity removed!"))
        }
        return () => {
            dispatch(stateReset())
        }
    }, [dispatch, isError, isDeleted, message])

    const handlePageChange = (event) => {
        setCurrentPage(event.selected + 1)
    }

    const onDateChange = (e) => {
        if (e !== undefined && e.target.value !== operatorId) {
            setDate(e.target.value)
            setCurrentPage(1)
        }
    }

    const onOperatorChange = (e) => {
        if (e !== undefined && e.value !== operatorId) {
            setOperatorId(e.value)
            setCurrentPage(1)
        }
    }
    const onProjectChange = (e) => {
        if (e !== undefined && e.value !== projectId) {
            setProjectId(e.value)
            setCurrentPage(1)
        }
    }

    useEffect(() => {
        dispatch(getStorageUsers())
        dispatch(getProjects())
        const queryParams = [
            { key: "page", value: currentPage },
            { key: "date", value: date },
            { key: "operatorId", value: operatorId },
            { key: "projectId", value: projectId },
        ]
        dispatch(getOperatorTimeActivities(queryParams))

        return () => {
            dispatch(reset())
        }
    }, [dispatch, currentPage, date, operatorId, projectId])

    if (isLoading) {
        return <Spinner />
    }

    const activitiesTableRow = activities.map((activity) => {
        const createdAt = new Date(activity.createdAt).toLocaleString("en-GB")
        return (
            <tr key={activity.id}>
                <th scope="row">{activity.id}</th>
                <td>{activity.operator.name}</td>
                <td>{activity.project.projectIdentifier}</td>
                <td>{activity.shortTimeFormat}</td>
                <td>{activity.activityDescription}</td>
                <td>{createdAt}</td>
                <td>
                    <MDBBtn size="sm" color="danger" onClick={() => dispatch(deleteActivity(activity.id))}>
                        Delete
                    </MDBBtn>
                </td>
            </tr>
        )
    })

    return (
        <MainWrapper>
            <MDBRow>
                <MDBCol>
                    <MDBCard className="m-3" border="primary">
                        <MDBCardHeader tag="h4">Inventory Documents</MDBCardHeader>
                        <MDBCardBody>
                            <AlertMessage></AlertMessage>
                            <MDBRow>
                                <MDBCol size="3">
                                    <MDBInput className="mb-2" type="date" id="date" label="Date" name="date" value={date} onChange={onDateChange} required />
                                </MDBCol>
                                <MDBCol size="2">
                                    <MDBSelect
                                        className="mb-2"
                                        id="operator_list"
                                        label="Operators"
                                        name="operator"
                                        data={selectUserOptions}
                                        getValue={(e) => onOperatorChange(e)}
                                        autoComplete="off"
                                        search
                                    />
                                </MDBCol>
                                <MDBCol size="2">
                                    <MDBSelect
                                        className="mb-2"
                                        id="project_list"
                                        label="Projects"
                                        name="project"
                                        data={selectProjectOptions}
                                        getValue={(e) => onProjectChange(e)}
                                        autoComplete="off"
                                        search
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBTable small>
                                <MDBTableHead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Operator</th>
                                        <th scope="col">Project ID</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">CreatedAt</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>{activitiesTableRow}</MDBTableBody>
                            </MDBTable>

                            <ReactPaginate
                                nextLabel="Next"
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                pageCount={activitiesPagination.last_page}
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                onPageChange={handlePageChange}
                                forcePage={currentPage - 1}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination justify-content-center"
                                activeClassName="active"
                            />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MainWrapper>
    )
}

export default OperatorTimeActivities
