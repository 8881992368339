import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuthStatus } from '../hooks/useAuthStatus'
import Spinner from './Spinner'
import authorizationCheck from "../utils/authorizationCheck"

const ProtectedRoutesGroup = ({  allowedRules, allowedIds }) => {
  const location = useLocation()
  const { loggedIn, checkingStatus, user, userRules } = useAuthStatus()
  const isAllowed = authorizationCheck(
    user,
    userRules,
    allowedRules,
    allowedIds
)

  if (checkingStatus) {
    return <Spinner />
  }

  if(!isAllowed){
    return <Navigate to="/" state={{ from: location }} />
  }

  return loggedIn ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />
}

ProtectedRoutesGroup.defaultProps = {
  allowedRules: [],
  allowedIds: []
}

export default ProtectedRoutesGroup