import { Navigate, useLocation } from "react-router-dom"
import { useAuthStatus } from "../hooks/useAuthStatus"
import authorizationCheck from "../utils/authorizationCheck"
import Spinner from "./Spinner"

const ProtectedRoute = ({ children, allowedRules, allowedIds }) => {
    const { loggedIn, checkingStatus, user, userRules } = useAuthStatus()
    const location = useLocation()
    const isAllowed = authorizationCheck(
        user,
        userRules,
        allowedRules,
        allowedIds
    )

    if (checkingStatus) {
        return <Spinner />
    }

    if (!isAllowed) {
        return <Navigate to="/" state={{ from: location }} />
    }

    if (!loggedIn) {
        return <Navigate to="/login" state={{ from: location }} />
    }

    return children
}

ProtectedRoute.defaultProps = {
    allowedRules: [],
    allowedIds: [],
}

export default ProtectedRoute
