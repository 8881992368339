import {
  MDBContainer,
  MDBNavbar,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { FaSignOutAlt } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout, reset } from "../../features/auth/authSlice";

export default function Navbar({ updateSidenav, sidenavState }) {
  const { user } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate("/login")
};

  return (
    <MDBNavbar id="main-navbar" expand="lg" light fixed="top" bgColor="light">
      <MDBContainer fluid>
        <MDBBtn onClick={() => updateSidenav(!sidenavState)} className="shadow-0 p-0 me-3 d-block d-xxl" color="light">
          <MDBIcon icon="bars" size="lg" fas />
        </MDBBtn>

        <MDBNavbarNav className="ms-auto d-flex flex-row">
          <MDBNavbarItem>
            <MDBDropdown>
              <MDBDropdownToggle
                style={{ cursor: "pointer" }}
                tag="a"
                className="nav-link hidden-arrow d-flex align-items-center"
              >
                {user?.name}
              </MDBDropdownToggle>
              <MDBDropdownMenu style={{ zIndex: 1060 }}>
                <MDBDropdownItem>
                  <MDBDropdownLink>My profile</MDBDropdownLink>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <MDBDropdownLink>Settings</MDBDropdownLink>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <MDBDropdownLink onClick={onLogout}>
                    <FaSignOutAlt /> Logout
                  </MDBDropdownLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBContainer>
    </MDBNavbar>
  );
}
