import { useSelector, useDispatch } from "react-redux";
import { MDBAlert } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { resetMessage } from "../features/messages/alertMessageSlice";

const AlertMessage = () => {
    const dispatch = useDispatch();
    const { isError, isSuccess, alertMessage, messageList } = useSelector(
        (state) => state.alertMessage
    );

    useEffect(() => {
        return () => {
            dispatch(resetMessage());
        };
    }, [dispatch]);

    const color = isError ? "danger" : "success";

    const AlertContainer = () => {
        if (alertMessage) {
            return (
                <MDBAlert show className="w-100 small p-2" color={color}>
                    {alertMessage}
                </MDBAlert>
            );
        }

        if (messageList.length > 0) {
            return messageList.map((message, index) => {
                return (
                    <MDBAlert show className="w-100 small p-0" key={index} color={color}>
                        {message}
                    </MDBAlert>
                );
            });
        }

        return <></>;
    };

    return isError || isSuccess ? AlertContainer() : <></>;
};

export default AlertMessage;
