import { useState } from "react";
import { useAuthStatus } from "../hooks/useAuthStatus";
import Sidenav from "./modules/Sidenav";
import Navbar from "./modules/Navbar";

function Header() {
    const [basicOpen, setBasicOpen] = useState(true);

    // const { user } = useSelector((state) => state.auth)
    const { loggedIn } = useAuthStatus();

    return (
        <header>
            {loggedIn ? (
                <>
                    <Sidenav
                        basicOpen={basicOpen}
                        setBasicOpen={setBasicOpen}
                    />
                    <Navbar
                        updateSidenav={setBasicOpen}
                        sidenavState={basicOpen}
                    />
                </>
            ) : (
                <></>
            )}
        </header>
    );
}

export default Header;
