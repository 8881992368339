import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    getEcomClients,
    getStorageClients,
    getInventoryDocuments,
    reset,
} from "../../../features/storage/inventory_documents/inventoryDocumentsSlice"
import { setErrorMessage } from "../../../features/messages/alertMessageSlice"
import Spinner from "../../../components/Spinner"
import MainWrapper from "../../../components/MainWrapper"
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBRow,
    MDBSelect,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from "mdb-react-ui-kit"
import ReactPaginate from "react-paginate"
import AlertMessage from "../../../components/AlertMessage"
import { Link } from "react-router-dom"

function InventoryDocuments() {
    const {
        ecomClients,
        storageClients,
        inventoryDocuments,
        inventoryDocumentsPagination,
        isLoading,
        isError,
        message,
    } = useSelector((state) => state.inventoryDocuments)
    const [currentPage, setCurrentPage] = useState(1)
    const [department, setDepartment] = useState()
    const [client, setClient] = useState()

    const dispatch = useDispatch()

    const mappedClients = () => {
        let arrEcom = []
        let arrStorage = []

        arrEcom = ecomClients.map((ecomClient) => {
            return {
                text: ecomClient.auth_group.name,
                value: ecomClient.group_id,
                selected: client === ecomClient.group_id ? true : false,
            }
        })

        arrStorage = storageClients.map((storageClient) => {
            return {
                text: storageClient.auth_group.name,
                value: storageClient.group_id,
                selected: client === storageClient.group_id ? true : false,
            }
        })

        return [
            { text: "", value: undefined, selected: false },
            ...arrEcom,
            ...arrStorage,
        ]
    }

    useEffect(() => {
        if (isError) {
            dispatch(setErrorMessage(message))
        }
        return () => {
            dispatch(reset())
        }
    }, [dispatch, isError, message])

    const handlePageChange = (event) => {
        setCurrentPage(event.selected + 1)
    }

    const onDepartmentChange = (e) => {
        if (e !== undefined && e.value !== department) {
            setDepartment(e.value)
            setCurrentPage(1)
        }
    }
    const onClientChange = (e) => {
        if (e !== undefined && e.value !== client) {
            setClient(e.value)
            setCurrentPage(1)
        }
    }

    useEffect(() => {
        dispatch(getEcomClients())
        dispatch(getStorageClients())
        const queryParams = [
            { key: "page", value: currentPage },
            { key: "department", value: department },
            { key: "client", value: client },
        ]
        dispatch(getInventoryDocuments(queryParams))

        return () => {
            dispatch(reset())
        }
    }, [dispatch, currentPage, department, client])

    if (isLoading) {
        return <Spinner />
    }

    const InventoryDocumentTableRow = inventoryDocuments.map(
        (inventoryDocument) => {
            const createdAt = new Date(inventoryDocument.created_at).toLocaleString('en-GB')
            return (
                <tr key={inventoryDocument.id}>
                    <th scope="row">{inventoryDocument.id}</th>
                    <td>{inventoryDocument.department}</td>
                    <td>{inventoryDocument?.auth_group?.name}</td>
                    <td>{inventoryDocument.reception_id}</td>
                    <td>{inventoryDocument.reception_date}</td>
                    <td>{createdAt}</td>
                    <td>
                        <Link
                            to={`/erp/storage/documents/${inventoryDocument.id}`}
                        >
                            <MDBBtn size="sm">View</MDBBtn>
                        </Link>
                    </td>
                </tr>
            )
        }
    )

    return (
        <MainWrapper>
            <MDBRow>
                <MDBCol>
                    <MDBCard className="m-3" border="primary">
                        <MDBCardHeader tag="h4">
                            Inventory Documents
                        </MDBCardHeader>
                        <MDBCardBody>
                            <AlertMessage></AlertMessage>
                            <MDBRow>
                                <MDBCol size="2">
                                    <MDBSelect
                                        className="mb-2"
                                        id="department"
                                        label="Filter department"
                                        name="department"
                                        data={[
                                            {
                                                text: "Ecommerce",
                                                value: "ecommerce",
                                                selected:
                                                    department === "ecommerce"
                                                        ? true
                                                        : false,
                                            },
                                            {
                                                text: "Storage",
                                                value: "storage",
                                                selected:
                                                    department === "storage"
                                                        ? true
                                                        : false,
                                            },
                                        ]}
                                        getValue={(e) => onDepartmentChange(e)}
                                    />
                                </MDBCol>
                                <MDBCol size="2">
                                    <MDBSelect
                                        className="mb-2"
                                        id="client_list"
                                        label="Clients"
                                        name="client"
                                        data={mappedClients()}
                                        getValue={(e) => onClientChange(e)}
                                        autoComplete="off"
                                        search
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBTable small>
                                <MDBTableHead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Department</th>
                                        <th scope="col">Client</th>
                                        <th scope="col">RecId</th>
                                        <th scope="col">RecDate</th>
                                        <th scope="col">CreatedAt</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {InventoryDocumentTableRow}
                                </MDBTableBody>
                            </MDBTable>

                            <ReactPaginate
                                nextLabel="Next"
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                pageCount={
                                    inventoryDocumentsPagination.last_page
                                }
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                onPageChange={handlePageChange}
                                forcePage={currentPage - 1}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination justify-content-center"
                                activeClassName="active"
                            />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MainWrapper>
    )
}

export default InventoryDocuments
