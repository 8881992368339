import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    getInventoryDocument,
    reset,
} from "../../../features/storage/inventory_documents/inventoryDocumentsSlice"
import { setErrorMessage } from "../../../features/messages/alertMessageSlice"
import Spinner from "../../../components/Spinner"
import MainWrapper from "../../../components/MainWrapper"
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from "mdb-react-ui-kit"
import AlertMessage from "../../../components/AlertMessage"
import { useParams } from "react-router-dom"
import { authConfig } from "../../../utils/authorizationUtils"
import { saveAs } from "file-saver"
import { downloaderAxios } from "../../../utils/loggerAxios"

function ShowInventoryDocument() {
    const { inventoryDocument, isLoading, isError, message } = useSelector(
        (state) => state.inventoryDocuments
    )
    const inventoryDocumentLoaded = Object.keys(inventoryDocument).length !== 0

    const dispatch = useDispatch()
    const params = useParams()
    const { id } = params

    useEffect(() => {
        if (isError) {
            dispatch(setErrorMessage(message))
        }
        return () => {
            dispatch(reset())
        }
    }, [dispatch, isError, message])

    useEffect(() => {
        dispatch(getInventoryDocument(id))

        return () => {
            dispatch(reset())
        }
    }, [dispatch, id])

    if (isLoading) {
        return <Spinner />
    }

    const downloadFile = (e) => {
        downloaderAxios
            .get(
                downloaderAxios.defaults.baseUrl +
                    "erp/storage/inventory-proof/download/" +
                    e.target.value,
                authConfig
            )
            .then((response) => {
                const blob = new Blob([response.data])
                const fileName = (Math.random() + 1).toString(36).substring(10)
                saveAs(blob, "File" + fileName + ".pdf")
            })
            .catch((error) => {
                console.log("File download issue.")
                // console.log(error)
            })
    }

    if (inventoryDocumentLoaded) {
        const InventoryDocumentContentRow =
            inventoryDocument.inventory_proof_contents.map(
                (InventoryDocumentContent) => (
                    <tr
                        key={InventoryDocumentContent.id}
                        className="text-center"
                    >
                        <td>{InventoryDocumentContent.description}</td>
                        <td>{InventoryDocumentContent.quantity}</td>
                    </tr>
                )
            )
        const InventoryDocumentFileRow =
            inventoryDocument.inventory_proof_documents.map(
                (inventoryDocumentFile) => (
                    <tr key={inventoryDocumentFile.id} className="text-center">
                        <td>{inventoryDocumentFile.id}</td>
                        <td>
                            <MDBBtn
                                size="sm"
                                value={inventoryDocumentFile.id}
                                onClick={(e) => downloadFile(e)}
                            >
                                Download
                            </MDBBtn>
                        </td>
                    </tr>
                )
            )

        return (
            <MainWrapper>
                <MDBRow>
                    <AlertMessage></AlertMessage>
                    <MDBCol size="6">
                        <MDBListGroup className="m-3" tag="div">
                            <MDBListGroupItem>
                                <div className="d-flex w-100 justify-content-between">
                                    <span>Id</span>
                                    <span>{inventoryDocument.id}</span>
                                </div>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <div className="d-flex w-100 justify-content-between">
                                    <span>Department</span>
                                    <span>{`${inventoryDocument.department[0].toUpperCase()}${inventoryDocument.department.slice(
                                        1
                                    )}`}</span>
                                </div>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <div className="d-flex w-100 justify-content-between">
                                    <span>Client</span>
                                    <span>
                                        {inventoryDocument.auth_group.name}
                                    </span>
                                </div>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                <div className="d-flex w-100 justify-content-between">
                                    <span>Reception Date</span>
                                    <span>
                                        {inventoryDocument.reception_date}
                                    </span>
                                </div>
                            </MDBListGroupItem>
                        </MDBListGroup>
                    </MDBCol>
                    <MDBCol size="6">
                        <MDBCard className="m-3">
                            <MDBCardHeader tag="h5">
                                Inventory Document Contents
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBTable small>
                                    <MDBTableHead>
                                        <tr className="text-center">
                                            <th scope="col">Item</th>
                                            <th scope="col">Qty</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {InventoryDocumentContentRow}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCard className="m-3">
                            <MDBCardHeader tag="h5">
                                Inventory Document Files
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBTable small>
                                    <MDBTableHead>
                                        <tr className="text-center">
                                            <th scope="col">Id</th>
                                            <th scope="col">File</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {InventoryDocumentFileRow}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MainWrapper>
        )
    }

    return <></>
}

export default ShowInventoryDocument
