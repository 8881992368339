import { MDBBtn, MDBRow, MDBCol, MDBContainer } from "mdb-react-ui-kit"
import MainWrapper from "../components/MainWrapper"
import { Link } from "react-router-dom"
import { useAuthStatus } from "../hooks/useAuthStatus"
import Spinner from "../components/Spinner"

function Home() {
    const { loggedIn, checkingStatus, user } = useAuthStatus()

    if (checkingStatus) {
        return <Spinner />
    }

    const WelcomeCard = () => {
        if (loggedIn) {
            return (
                <MDBCol className="d-flex justify-content-center mt-5">
                    <section className="my-5 text-center">
                        <h1 className="display-1">Welcome,</h1>
                        <h4 className="mb-4">{user?.name}</h4>
                        <Link
                            to="/dashboard"
                            className="d-grid gap-2 col-6 mx-auto"
                        >
                            <MDBBtn variant="outline-light">DASHBOARD</MDBBtn>
                        </Link>
                    </section>
                </MDBCol>
            )
        }

        return (
            <MDBCol>
                <h1 className="display-1 font-weight-bold text-end">
                    Welcome!
                </h1>
                <hr className="my-4" />
                <Link to="/login" className="float-end">
                    <MDBBtn className="btn btn-info btn-lg btn-rounded P-4">
                        LOGIN
                    </MDBBtn>
                </Link>
            </MDBCol>
        )
    }

    return (
        <MainWrapper>
            <MDBContainer>
                <MDBRow>
                    <WelcomeCard></WelcomeCard>
                </MDBRow>
            </MDBContainer>
        </MainWrapper>
    )
}

export default Home
