import axios from "axios";

const loggerAxios = axios.create({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "Accept": "application/json",
    },
});

loggerAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            // window.location.href = "/";
            console.log(error.response.data);
        }
        console.log(error.response.data);
        return Promise.reject(error);
    }
);

export const downloaderAxios = axios.create({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    responseType: 'blob', // important
    headers: {
        "Accept": "application/json",
    },
});

export default loggerAxios;
