import loggerAxios  from "./../../../utils/loggerAxios"
import { authConfig, authConfigMultipart } from "../../../utils/authorizationUtils"

const BASE_URL = loggerAxios.defaults.baseUrl
const API_URL = BASE_URL + "erp/storage/inventory-proof"

// Create new inventory document
const createDocument = async (documentData) => {
    const response = await loggerAxios.post(API_URL, documentData, authConfigMultipart)

    return response.data
}

// get available receptions
const getAvailableReceptions = async (groupId) => {
    const response = await loggerAxios.get(
        API_URL + "/available-receptions/" + groupId,
        authConfig
    )

    return response.data
}

// get Inventory Documents
const getInventoryDocuments = async (queryParams) => {
    let queryString = queryParams.reduce(function(queryString, param) {
        if(param.value !== undefined){
            queryString += param.key + "=" + param.value + "&"
        }
        return queryString;
    }, '')
    const response = await loggerAxios.get(
        API_URL + "?" + queryString.slice(0, -1),
        authConfig
    )

    return response.data
}

// get Inventory Document
const getInventoryDocument = async (id) => {
    const response = await loggerAxios.get(
        API_URL + "/" + id,
        authConfig
    )

    return response.data
}

const inventoryDocumentsService = {
    createDocument,
    getAvailableReceptions,
    getInventoryDocuments,
    getInventoryDocument,
}

export default inventoryDocumentsService
